import React, { useState } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import globalStrings from "../../config/index";
import EnsembleLogo from "./EnsembleLogo";
import MobileMenu from "./MobileMenu";

const NavBar = () => {
  const { logoAlt, services, about, careers, contact } =
    globalStrings.navigation;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    document.body.style.overflowY = isOpen ? "auto" : "hidden";
  };

  const menuItemClassCss = classNames(
    "text-sm border-transparent border-b uppercase font-normal leading-[19px] hover:border-ensemble-orange"
  );

  const activeMenuItemClassCss = classNames(
    "font-semibold !border-ensemble-orange"
  );

  const containerMenuItemClassCss = classNames(
    "min-w-[70px] flex justify-center"
  );
  return (
    <div
      data-testid="navbar"
      className=" border-b border-[#EFF3F4] shadow-md md:px-16 sm:px-8 px-4 sticky top-0 z-[100] bg-white"
    >
      <div className="container mx-auto py-5 lg:py-[26px] flex items-center justify-between lg:py-7">
        <Link className="flex items-center flex-shrink-0" to="/">
          <EnsembleLogo alt={logoAlt} />
        </Link>
        <MobileMenu onClick={toggleMenu} />
        <div className="flex items-center space-x-[33px] hidden lg:flex">
          <div className={containerMenuItemClassCss}>
            <Link
              className={menuItemClassCss}
              to="/services"
              activeClassName={activeMenuItemClassCss}
              partiallyActive
            >
              {services}
            </Link>
          </div>
          <div className={containerMenuItemClassCss}>
            <Link
              className={menuItemClassCss}
              to="/about"
              activeClassName={activeMenuItemClassCss}
              partiallyActive
            >
              {about}
            </Link>
          </div>
          <div className={containerMenuItemClassCss}>
            <Link
              className={menuItemClassCss}
              to="/careers"
              activeClassName={activeMenuItemClassCss}
              partiallyActive
            >
              {careers}
            </Link>
          </div>
          <div className={containerMenuItemClassCss}>
            <Link
              className={menuItemClassCss}
              to="/contact"
              activeClassName={activeMenuItemClassCss}
              partiallyActive
            >
              {contact}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
