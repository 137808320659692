import React from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import ensembleLogo from "../../images/footer/ensemble-logo-white.svg";
import linkedinLogo from "../../images/footer/linkedin-logo-white.svg";
import facebookLogo from "../../images/footer/facebook-logo-white.svg";
import instagramLogo from "../../images/footer/instagram-logo-white.svg";
import globalStrings from "../../config/index";
import Image from "../Image";

const dividerClass =
  "md:pb-0 pb-4 md:border-b-0 border-b border-white border-opacity-20";
const textClass = "text-white text-xs md:text-sm";

const Contact = () => {
  const { contact } = globalStrings.footer;

  return (
    <div className={`md:order-last space-y-4 ${dividerClass}`}>
      <div className={`font-semibold lg:font-bold lg:text-[16px] ${textClass}`}>
        {contact.header}
      </div>
      <ul className="space-y-4">
        <li
          data-testid="contact-element"
          className={classNames(textClass, "flex flex-row")}
        >
          <div className="md:text-[12px] lg:text-[14px] lg:font-semibold whitespace-nowrap">
            {contact.main.label}&nbsp;
          </div>
          <div className="font-normal md:text-[12px] lg:text-[14px] whitespace-nowrap">
            <a href={`tel:${contact.main.number}`}>{contact.main.number}</a>
          </div>
        </li>
        <li
          data-testid="contact-element"
          className={classNames(textClass, "flex flex-row")}
        >
          <div className="md:text-[12px] lg:text-[14px] lg:font-semibold">
            {contact.email.label}&nbsp;
          </div>
          <div className="font-normal md:text-[12px] lg:text-[14px]">
            <a href={`mailto:${contact.email.address}`}>
              {contact.email.address}
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};
const Location = () => {
  const { locations } = globalStrings.footer;
  return (
    <div className={`space-y-4 md:mt-0 mt-4 ${dividerClass}`}>
      <div className={`font-semibold lg:font-bold lg:text-[16px] ${textClass}`}>
        {locations.header}
      </div>
      <ul className="space-y-4">
        {locations.locations.map((location: string) => (
          <li
            data-testid="location-element"
            key={location}
            className={`md:text-[12px] lg:text-[14px] ${textClass}`}
          >
            {location}
          </li>
        ))}
      </ul>
    </div>
  );
};
const Links = () => {
  const { privacy, cookies, terms } = globalStrings.footer;

  return (
    <div className="md:flex justify-end">
      <ul className="space-y-4 md:mt-0 mt-4 md:text-right">
        <li data-testid="link-element">
          <Link className={`${textClass}`} to="/privacy">
            {privacy}
          </Link>
        </li>
        <li data-testid="link-element">
          <Link className={textClass} to="/terms">
            {terms}
          </Link>
        </li>
      </ul>
    </div>
  );
};
const SocialMedia = () => {
  const { linkedin, facebook, instagram } = globalStrings.footer;
  return (
    <ul className="flex justify-center md:justify-start gap-4">
      <li data-testid="social-media-element" className="inline-block">
        <a href={linkedin.url} target="__blank">
          <img
            className="mt-1 w-[30px] h-[30px]"
            src={linkedinLogo}
            alt={linkedin.alt}
          />
        </a>
      </li>
      <li data-testid="social-media-element" className="inline-block">
        <a href={facebook.url} target="__blank">
          <img
            className="mt-1 w-[30px] h-[30px]"
            src={facebookLogo}
            alt={facebook.alt}
          />
        </a>
      </li>
      <li data-testid="social-media-element" className="inline-block">
        <a href={instagram.url} target="__blank">
          <img
            className="mt-1 w-[30px] h-[30px]"
            src={instagramLogo}
            alt={instagram.alt}
          />
        </a>
      </li>
    </ul>
  );
};
const Logo = () => {
  const { logoAlt } = globalStrings.footer;
  return (
    <div
      data-testid="logo-container"
      className="md:flex-grow md:mx-0 mx-auto md:mb-0"
    >
      <img src={ensembleLogo} alt={logoAlt} />
    </div>
  );
};

const Copyrights = () => {
  const { copyright } = globalStrings.footer;
  return (
    <div data-testid="copyright-container" className="space-y-2">
      <div className="text-white md:text-right text-center text-10px md:text-sm">
        {copyright.one.replace("{year}", `${new Date().getFullYear()}`)}
      </div>
      <div className="text-white md:text-right text-center text-10px md:text-sm">
        {copyright.two}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div className="relative bg-ensemble-dark-blue w-full h-full z-0">
      <div className="flex md:justify-center">
        <div className="flex-col flex-grow px-[40px] py-[32px] max-w-full z-10 md:flex md:py-[60px] md:px-[100px]">
          <div className="md:grid md:grid-cols-2 lg:gap-64 xl:gap-96">
            <div className="md:grid grid-cols-2">
              <Contact />
              <Location />
            </div>
            <div className="md:grid grid-cols-1 justify-between">
              <Links />
            </div>
          </div>
          <div className="hidden md:grid grid-cols-2 md:mt-14 lg:gap-64 xl:gap-96">
            <div className="mt-6 items-center flex flex-col grid-cols-2 md:self-end md:grid md:mt-auto md:h-full md:pt-2">
              <Logo />
              <div className="flex justify-center order-first md:order-none  md:flex md:justify-self-start gap-2">
                <SocialMedia />
              </div>
            </div>
            <div className="mt-3 md:mt-auto">
              <Copyrights />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mb-[40px] md:hidden">
        <div className="grid-cols-4 flex flex-col content-center items-center">
          <div className="mt-6 items-center flex flex-col grid-cols-2">
            <Logo />
          </div>
          <div className="flex justify-center order-first md:order-none  md:flex md:justify-self-start gap-2">
            <SocialMedia />
          </div>
          <div className="hidden md:block" />
          <div className="mt-3 md:mt-auto">
            <Copyrights />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
